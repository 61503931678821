<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 22.5c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1.75 20.5v-10c0-.283.096-.521.288-.713A.967.967 0 0 1 2.75 9.5a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v10h16c.283 0 .52.096.712.288a.965.965 0 0 1 .288.712c0 .283-.096.52-.288.712a.965.965 0 0 1-.712.288h-16zm4-4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5.75 16.5v-9c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 7.75 5.5h3v-2c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12.75 1.5h4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2h3c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v9c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 0 1-1.413.587h-14zm5-13h4v-2h-4v2z"
      fill="#54718E"
    />
  </svg>
</template>
